import { useQuery } from 'react-query';

import getGroupPaymentProvider from 'api/payment/getGroupPaymentProvider';

import { QUERY_IDS } from 'api';

const { GET_GROUP_PAYMENT_PROVIDER } = QUERY_IDS;

const useGetGroupPaymentProvider = ({ enabled }) => {
  return useQuery([GET_GROUP_PAYMENT_PROVIDER], getGroupPaymentProvider, {
    refetchOnWindowFocus: false,
    enabled,
  });
};

export default useGetGroupPaymentProvider;
