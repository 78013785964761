import { useParams } from 'react-router-dom';

import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import { GROUP_RESERVATIONS } from 'constants/featureToggleNames';

import useGetListingById from './useGetListingById';
import useSearchValues from './useSearchValues';

const MULTI_UNIT_PROPERTY_TYPE = 'MTL';

const useIsGroupReservation = () => {
  const [isGroupReservationFTLoading, isGroupReservationEnabled] = useFeatureToggle(GROUP_RESERVATIONS);
  const { id } = useParams();
  const { rooms } = useSearchValues();
  const { property, isGettingListing } = useGetListingById({ id });

  const isLoading = isGroupReservationFTLoading || isGettingListing;
  const isGroupReservation = isGroupReservationEnabled && property?.type === MULTI_UNIT_PROPERTY_TYPE && rooms > 1;

  return {
    isGroupReservationEnabled,
    isLoading,
    isGroupReservation,
  };
};

export default useIsGroupReservation;
