import { useRef, useEffect } from 'react';
import TextField from '@guestyci/foundation/TextField';
import createStyles from '@guestyci/foundation/createStyles';
import Button from '@guestyci/foundation/Button';
import cn from 'classnames';
import Image from 'components/Image/Image';
import PriceConverter from 'components/PriceConverter';
import t from '@guestyci/localize/t.macro';

import useIsMobile from 'hooks/useIsMobile';
import useDio from 'hooks/useDio';
import { useUpsellContext } from 'context/UpsellContext';
import Counter from './Counter';
import useIsOverflow from '../../hooks/useIsOverflow';

// was calculated by divide row char length on row px size in Figma
const PX_PER_CHAR = 6;
const CARD_ROWS_COUNT = 4;
const MIN_EXPANDED_WIDTH = 315;

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    border: 'solid #D1D5DB 1px',
    borderRadius: 8,
    height: 300,
    marginBottom: 10,
  },
  mobileImg: {
    height: '106px !important',
  },
  normal: {
    animationFillMode: 'forwards',
    animationDuration: '0.3s',
    animationTimingFunction: 'ease',
    animationName: '$closeAnimation',
    [create('md')]: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 180,
    },
    [create('xs')]: {
      width: 180,
    },
  },
  expand: {
    animationFillMode: 'forwards',
    animationDuration: '0.3s',
    animationTimingFunction: 'ease',
    animationName: '$openAnimation',
    [create('md')]: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 'calc(var(--container-width, 315px))',
    },
    [create('xs')]: {
      width: 'calc(var(--container-width, 315px))',
    },
  },
  image: {
    '& > img': {
      width: '100%',
      height: 130,
      objectFit: 'cover',
    },
  },
  title: {
    padding: '10px 10px 0px 10px',
  },
  disc: {
    fontSize: 12,
  },
  bottom: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 10,
    paddingRight: 10,
    marginBottom: 10,
    marginTop: 'auto',
  },
  btnColor: {
    color: '#206CFF',
  },
  ellipsis: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 'calc(var(--line-clamp, 3))',
    overflow: 'hidden',
    position: 'relative',
    margin: 0,
  },
  btnSmall: {
    display: 'inline-block',
    fontSize: 12,
    pointerEvents: 'auto',
  },
  '@keyframes openAnimation': {
    '0%': {
      flexBasis: 180,
    },
    '100%': {
      flexBasis: 'calc(var(--container-width, 315px))',
    },
  },
  '@keyframes closeAnimation': {
    '0%': {
      flexBasis: 'calc(var(--container-width, 315px))',
    },
    '100%': {
      flexBasis: 180,
    },
  },
}));

const replaceMarkdownWithHtmlLinks = (text) => {
  const regex = /\[(.*?)\]\((.*?)\)/g;

  const parts = text.split(regex);

  return parts.map((part, index) => {
    if (index % 3 === 1) {
      const url = parts[index + 1];
      return (
        <a key={url} href={url} target="_blank" rel="noreferrer">
          {part}
        </a>
      );
    }
    if (index % 3 === 2) {
      return null;
    }
    return part;
  });
};

const getImage = (list) => {
  const defaultImg = list.find((item) => item.isDefault);
  if (defaultImg) return defaultImg.url;
  return list.length > 0 ? list[0].url : '';
};

const calculateCardExpandedWidth = (size) => {
  if (!size) return 315;
  const charactersPerRow = Math.round(size / CARD_ROWS_COUNT);
  return charactersPerRow * PX_PER_CHAR < MIN_EXPANDED_WIDTH ? MIN_EXPANDED_WIDTH : charactersPerRow * PX_PER_CHAR;
};

const CardBody = ({ item, onReadmoreClick, isExpanded = false, currency, source, cardRef }) => {
  const {
    _id: upsellId,
    upsell: { description, images },
    name,
    price,
  } = item;

  const descriptionContainerRef = useRef(null);
  const isOverflow = useIsOverflow(descriptionContainerRef, []);

  const { image, title, btnSmall, disc, bottom, btnColor, mobileImg, ellipsis } = useStyles();

  const isMobile = useIsMobile();

  const { dioTrack } = useDio();

  const { getUpsellItemCountForListingById, setUpsellItemCountForListingById } = useUpsellContext();

  const count = getUpsellItemCountForListingById(upsellId);

  const handleCount = (value) => {
    setUpsellItemCountForListingById(item, value);
  };

  const trackAdd = (upsellCount) => {
    dioTrack('click_got_upsell', 'click', { upsell: item, source, count: upsellCount });
  };

  const trackRemove = (upsellCount) => {
    dioTrack('click_remove_upsell', 'click', { upsell: item, source, count: upsellCount });
  };

  const trackAddButton = (upsellCount) => {
    dioTrack('click_got_upsell', 'click', { upsell: item, source, count: upsellCount });
    handleCount(upsellCount);
  };

  useEffect(() => {
    if (cardRef?.current) {
      cardRef.current.style.setProperty(
        '--container-width',
        `${calculateCardExpandedWidth(descriptionContainerRef.current?.innerText.length)}px`
      );
    }
  }, [descriptionContainerRef.current?.innerText, cardRef]);

  useEffect(() => {
    if (descriptionContainerRef?.current) {
      descriptionContainerRef.current.style.setProperty('--line-clamp', `${isMobile ? 1 : 3}`);
    }
  }, [isMobile]);

  const bottomSection =
    count > 0 ? (
      <Counter max={1} min={0} value={count} onChange={handleCount} onAdd={trackAdd} onRemove={trackRemove} />
    ) : (
      <Button className={btnColor} onClick={() => trackAddButton(1)}>
        {t('Add')}
      </Button>
    );

  return (
    <>
      <div className={image}>
        {isMobile ? (
          <Image alt="property" src={getImage(images)} className={mobileImg} />
        ) : (
          <Image alt="property" src={getImage(images)} />
        )}
      </div>
      <div className={title}>
        <TextField bold>{name}</TextField>
        <div>
          <TextField ref={descriptionContainerRef} color="default" className={cn(disc, { [ellipsis]: !isExpanded })}>
            {replaceMarkdownWithHtmlLinks(description)}
            {isExpanded && !isMobile && (
              <Button className={cn(btnSmall, btnColor)} onClick={() => onReadmoreClick(item)}>
                {t('Show less')}
              </Button>
            )}
          </TextField>
          {!isExpanded && isOverflow && (
            <Button className={cn(btnSmall, btnColor)} onClick={() => onReadmoreClick(item)}>
              {t('Show more')}
            </Button>
          )}
        </div>
      </div>
      <div className={bottom}>
        <TextField size={16}>
          <PriceConverter amount={price} currency={currency ?? 'usd'} />
        </TextField>
        {bottomSection}
      </div>
    </>
  );
};

const Card = ({ item, onReadmoreClick, isExpanded = false, className, currency, source }) => {
  const { card, normal, expand } = useStyles();
  const cardRef = useRef(null);
  return (
    <div ref={cardRef} className={cn(card, className, { [normal]: !isExpanded, [expand]: isExpanded })}>
      <CardBody
        item={item}
        onReadmoreClick={onReadmoreClick}
        isExpanded={isExpanded}
        currency={currency}
        source={source}
        cardRef={cardRef}
      />
    </div>
  );
};

export { CardBody };

export default Card;
