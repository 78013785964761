import { useQuery } from 'react-query';

import getListingPaymentProvider from 'api/listings/getListingPaymentProvider';

import { QUERY_IDS } from 'api';

const { GET_LISTING_PAYMENT_PROVIDER } = QUERY_IDS;

const useGetListingPaymentProvider = ({ listingId, enabled }) => {
  const { data, isLoading, isError, error, isSuccess } = useQuery(
    [GET_LISTING_PAYMENT_PROVIDER, listingId],
    () => getListingPaymentProvider({ listingId }),
    {
      refetchOnWindowFocus: false,
      enabled,
    }
  );

  return {
    data,
    isLoading,
    isError,
    isSuccess,
    error,
  };
};

export default useGetListingPaymentProvider;
