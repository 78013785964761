import { useContext, useState, useMemo } from 'react';
import countryList from 'react-select-country-list';
import debounce from 'lodash/debounce';

import FormField from '@guestyci/foundation/FormField';
import PhonePicker from '@guestyci/foundation/PhonePicker';
import Input from '@guestyci/foundation/Input';
import TextArea from '@guestyci/foundation/TextArea';
import EmailInput from '@guestyci/foundation/EmailInput';
import { email } from '@guestyci/foundation/validators';
import TextField from '@guestyci/foundation/TextField';
import { Row } from '@guestyci/foundation/Layout';
import Dropdown, { Option } from '@guestyci/foundation/Dropdown';
import createStyles from '@guestyci/foundation/createStyles';
import t from '@guestyci/localize/t.macro/t.macro';
import { INVALID_PHONE } from '@guestyci/foundation/validators/validationMessages';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import { useUpsellContext } from 'context/UpsellContext';
import { checkIsPhoneCodeOnly } from 'utils';
import useIsGroupReservation from 'hooks/useIsGroupReservation';
import FullPageLoader from 'components/FullPageLoader';
import GroupBooker from './GroupBooker';

const phoneNumberValidator = (value, required) => {
  return required && (!value || checkIsPhoneCodeOnly(value)) ? INVALID_PHONE : undefined;
};

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  root: {
    [create('xs')]: {
      width: '100%',
    },
    [create('xl')]: {
      width: 560,
    },
    '& .form-field': {
      '& div[class*="Input-root"], div[class*="Input-root"], div[class*="Dropdown-root"], div[class*="dialCodeInput"]':
        {
          backgroundColor: '#fff',
        },
    },
  },
  title: {
    marginBottom: 30,
  },
  twoInRow: {
    marginBottom: 20,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& > div': {
      [create('xs')]: {
        width: '100%',
        marginBottom: 10,
      },
      [create('xl')]: {
        width: 269,
        marginBottom: 0,
      },
    },
  },
  threeInRow: {
    marginBottom: 20,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& > div': {
      [create('xs')]: {
        width: '100%',
        marginBottom: 10,
      },
      [create('xl')]: {
        width: 173,
        marginBottom: 0,
      },
    },
  },
}));

const GuestInformation = () => {
  const { isGroupReservation, isGroupReservationFTLoading } = useIsGroupReservation();
  const { contentConfiguration = {} } = useContext(WebsiteSettingsContext);
  const { hasTravelCoverage } = useUpsellContext();
  const options = useMemo(() => countryList().getData(), []);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const { phoneNumber = { active: true, required: false }, guestsRequests = { active: true, required: false } } =
    contentConfiguration.guestInformationRequest || {};

  const formFieldNamePrefix = isGroupReservation ? 'groupBooker.' : '';

  const { root, title, twoInRow, threeInRow } = useStyles();

  const getFullFildName = (fieldName) => `${formFieldNamePrefix}${fieldName}`;

  const handleCountrySearch = debounce((e) => {
    const { value } = e.target;
    const filtered = options.filter(({ label }) => label.toLowerCase().includes(value.toLowerCase()));
    setFilteredOptions(filtered);
  }, 250);

  if (isGroupReservationFTLoading) {
    <FullPageLoader />;
  }

  const isPhoneNumberActive = phoneNumber?.active || hasTravelCoverage;
  const isPhoneNumberRequired = phoneNumber?.required || hasTravelCoverage;

  return (
    <div className={root}>
      <TextField variant="h3" className={title}>
        {t('Guest information')}
      </TextField>
      <Row className={twoInRow}>
        <FormField className="form-field" label={t('First name')} name={getFullFildName('firstName')} required>
          <Input placeholder={t('Guest first name *')} />
        </FormField>
        <FormField className="form-field" label={t('Last name')} name={getFullFildName('lastName')} required>
          <Input placeholder={t('Guest last name *')} />
        </FormField>
      </Row>
      <Row className={twoInRow}>
        <FormField
          className="form-field"
          label={t('Email')}
          name={getFullFildName('email')}
          validate={[email]}
          required
        >
          <EmailInput withActions={false} placeholder={t('Email address *')} />
        </FormField>
        {isPhoneNumberActive && (
          <FormField
            validate={[(value) => phoneNumberValidator(value, isPhoneNumberRequired)]}
            required={isPhoneNumberRequired}
            className="form-field"
            label={t('Phone number')}
            name={getFullFildName('phone')}
          >
            <PhonePicker
              withActions={false}
              placeholder={t('Enter phone number <r></r>', { r: () => (isPhoneNumberRequired ? '*' : '') })}
            />
          </FormField>
        )}
      </Row>
      {guestsRequests?.active && (
        <Row>
          <FormField
            required={guestsRequests?.required}
            className="form-field w-100 mb-4"
            label={t('Add a special request')}
            name={getFullFildName('message')}
          >
            <TextArea
              placeholder={t('Add a special request <r></r>', { r: () => (guestsRequests?.required ? '*' : '') })}
              minRows="5"
              maxRows="5"
            />
          </FormField>
        </Row>
      )}
      {hasTravelCoverage && (
        <>
          <Row className={twoInRow}>
            <FormField className="form-field" label={t('Street')} name={getFullFildName('street')} required>
              <Input placeholder={t('Street name *')} />
            </FormField>
            <FormField className="form-field" label={t('City')} name={getFullFildName('city')} required>
              <Input placeholder={t('City *')} />
            </FormField>
          </Row>
          <Row className={threeInRow}>
            <FormField className="form-field" label={t('State')} name={getFullFildName('state')} required>
              <Input placeholder={t('State *')} />
            </FormField>
            <FormField className="form-field" label={t('Zip code')} name={getFullFildName('zipCode')} required>
              <Input placeholder={t('Zip code *')} />
            </FormField>
            <FormField className="form-field" label={t('Country')} name={getFullFildName('country')} required>
              <Dropdown searchable onSearchQueryChange={handleCountrySearch}>
                {filteredOptions.map(({ label, value }) => (
                  <Option value={value} key={label}>
                    {label}
                  </Option>
                ))}
              </Dropdown>
            </FormField>
          </Row>
        </>
      )}
      <GroupBooker />
    </div>
  );
};

export default GuestInformation;
