import { useContext } from 'react';
import TextField from '@guestyci/foundation/TextField';
import { Row } from '@guestyci/foundation/Layout';
import PriceConverter from 'components/PriceConverter';
import Divider from '@guestyci/foundation/Divider';
import createStyles from '@guestyci/foundation/createStyles';
import Button from '@guestyci/foundation/Button';
import Icon from '@guestyci/foundation/Icon';
import { ReactComponent as BtnDeleteMobile } from '@guestyci/icons/BtnDeleteMobile.svg';
import { useUpsellContext } from 'context/UpsellContext';
import { CurrencyContext } from 'context/CurrencyContext';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';
import useDio from 'hooks/useDio';
import { UPSELL } from 'constants/featureToggleNames';

const useStyles = createStyles(() => ({
  dataContainer: {
    display: 'flex',
    '&:hover  $deleteButton': {
      display: 'block',
    },
  },
  deleteButton: {
    transform: 'scale(0.5)',
    marginLeft: 'auto',
    display: 'none',
  },
}));

const SelectedUpsellItems = ({ currency }) => {
  const { deleteButton, dataContainer } = useStyles();

  const { selectedUpsells, removeUpsellById } = useUpsellContext();
  const { defaultCurrency } = useContext(CurrencyContext);
  const { dioTrack } = useDio();

  const trackRemove = (id) => {
    dioTrack('click_remove_upsell', 'click', { upsell: selectedUpsells, source: 'quote', count: 0 });
    removeUpsellById(id);
  };

  const [, isFtEnabled] = useFeatureToggle(UPSELL);

  if (!isFtEnabled) return null;

  return (
    <>
      {selectedUpsells?.map((item) => (
        <Row key={item.name} justify="between" className="mt-2 mb-2">
          <div className={dataContainer}>
            <Button className={deleteButton} onClick={() => trackRemove(item._id)}>
              <Icon svg={BtnDeleteMobile} />
            </Button>
            <TextField>{item.name}</TextField>
          </div>

          <TextField>
            <PriceConverter amount={item.price} currency={currency ?? defaultCurrency} />
          </TextField>
        </Row>
      ))}
      <Divider />
    </>
  );
};

export default SelectedUpsellItems;
