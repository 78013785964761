import { isEmpty } from 'lodash';

import t from '@guestyci/localize/t.macro';
import { Row } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField/TextField';

import PROMOTION_TYPES from 'constants/promotionTypes';

const Promotion = ({ promotion, className }) => {
  if (isEmpty(promotion) || !promotion) return null;
  const {
    type,
    rule: { discountAmount, discountType },
  } = promotion;

  const getPromotionType = (promotionType = '') => {
    switch (promotionType) {
      case PROMOTION_TYPES.EARLY_BOOKER:
        return t('Early bird discount');
      case PROMOTION_TYPES.LAST_MINUTE:
        return t('Last-minute discount');
      default:
        return t('Discount');
    }
  };

  const discountTypeToSymbol = discountType === 'percent' ? '%' : '$';
  return (
    <Row className={className} justify="between">
      <Row align="center">
        <TextField>{getPromotionType(type)}</TextField>
      </Row>
      <TextField>{`-${discountAmount}${discountTypeToSymbol}`}</TextField>
    </Row>
  );
};

export default Promotion;
