import { useLocation, useHistory } from 'react-router-dom';

import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import useSearchValues from 'hooks/useSearchValues';
import FullPageLoader from 'components/FullPageLoader';

import useGetListingPaymentProvider from 'hooks/useGetListingPaymentProvider';
import useGetListingById from 'hooks/useGetListingById';
import useGetPathToNavigate from 'hooks/useGetPathToNavigate';
import { GROUP_RESERVATIONS } from 'constants/featureToggleNames';

import useIsGroupReservation from 'hooks/useIsGroupReservation';
import useGetGroupPaymentProvider from 'hooks/useGetGroupPaymentProvider';
import CheckOutInstantStripe from './CheckOutInstantStripe';
import CheckOutInstantAmaryllis from './CheckOutInstantAmaryllis';
import CheckOutInstantBankTransfer from './CheckOutInstantBankTransfer';

const STRIPE = 'stripe';
const AMARYLLIS = 'amaryllis';

const CheckOutInstantContainer = () => {
  const history = useHistory();
  const { state } = useLocation();
  const { getPathWithLocale } = useGetPathToNavigate();
  const redirectPath = getPathWithLocale('/properties');

  const [, isGroupReservationsFTEnabled] = useFeatureToggle(GROUP_RESERVATIONS);

  if (isGroupReservationsFTEnabled) {
    if (!state?.quoteData?.quote?.length || !state?.ratePlan?._id) {
      history.push(redirectPath);
    }
  } else if (!state?.quoteId || !state?.ratePlanId) {
    history.push(redirectPath);
  }

  const { listingId } = useSearchValues();
  const { property, isGettingListing } = useGetListingById({ id: listingId });
  const { isGroupReservation } = useIsGroupReservation();
  const { data: listingPaymentProvider, isLoading: isListingPPLoading } = useGetListingPaymentProvider({
    listingId,
    enabled: !isGroupReservation,
  });
  const { data: groupPaymentProvider, isLoading: isGroupPPLoading } = useGetGroupPaymentProvider({
    enabled: isGroupReservation,
  });
  const {
    providerAccountId,
    providerType,
    _id: providerId,
  } = (isGroupReservation ? groupPaymentProvider : listingPaymentProvider) || {};

  if (isGroupPPLoading || isListingPPLoading || isGettingListing) {
    return <FullPageLoader />;
  }

  switch (providerType) {
    case STRIPE:
      return <CheckOutInstantStripe providerAccountId={providerAccountId} property={property} />;
    case AMARYLLIS:
      return <CheckOutInstantAmaryllis providerId={providerId} property={property} />;
    default:
      return <CheckOutInstantBankTransfer property={property} />;
  }
};

export default CheckOutInstantContainer;
