import { Children, useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Dropdown from '@guestyci/foundation/Dropdown';
import t from '@guestyci/localize/t.macro';
import { Row } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import createStyles from '@guestyci/foundation/createStyles';
import useGetRoomsLabel from 'hooks/useGetRoomsLabel';
import IconInput from 'components/IconInput';

const useDropdownOptionStyles = createStyles((theme) => ({
  root: {
    padding: [15, 12],
    userSelect: 'none',
  },
  valueLabel: {
    color: theme.palette.label,
    fontSize: 14,
    fontWeight: 'bold',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    background: theme.palette.primary.light,
    color: '#fff',
    width: 16,
    height: 16,
    borderRadius: '50%',
    fontSize: 14,
    transition: 'background .3s ease',
    '&:hover': {
      background: theme.palette.primary.hover,
    },
    '&:disabled': {
      background: theme.palette.primary.disabled,
    },
  },
  controlsWrapper: {
    width: 84,
    justifyContent: 'space-between',
  },
}));

export const DropdownOption = ({
  value = 0,
  handleChange = () => {},
  label,
  min = 1,
  max = Infinity,
  disable = false,
  onChange = () => {},
}) => {
  const { root, button, valueLabel, controlsWrapper } = useDropdownOptionStyles();
  const inputRef = useRef();

  const increment = useCallback(() => {
    const newValue = Number(inputRef.current.value) + 1;
    if (newValue <= max) {
      const event = { target: { value: newValue } };
      handleChange(event);
      onChange(newValue);
    }
  }, [handleChange, onChange, max]);

  const decrement = useCallback(() => {
    const newValue = Number(inputRef.current.value) - 1;
    if (newValue >= min) {
      const event = { target: { value: newValue } };
      handleChange(event);
      onChange(newValue);
    }
  }, [handleChange, onChange, min]);

  return (
    <Row className={root} fullWidth justify="between" align="center">
      <TextField variant="h5">{label}</TextField>
      <Row className={controlsWrapper} align="center">
        <input
          ref={inputRef}
          type="number"
          value={value}
          onChange={handleChange}
          min={min}
          max={max}
          className="d-none"
        />
        <button className={button} type="button" onClick={decrement} disabled={disable || value <= min}>
          -
        </button>
        <span className={valueLabel}>{value}</span>
        <button className={button} type="button" onClick={increment} disabled={disable || value >= max}>
          +
        </button>
      </Row>
    </Row>
  );
};

DropdownOption.propTypes = {
  value: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  disable: PropTypes.bool,
  onChange: PropTypes.func,
};

DropdownOption.defaultProps = {
  min: 1,
  max: Infinity,
  disable: false,
  onChange: () => {},
};

const renderSelected = (values) => {
  const filteredValues = values?.filter(({ value }) => !!value);
  return filteredValues?.map(({ label, value = 1 }, index) => {
    return `${value} ${label} ${index === filteredValues.length - 1 ? '' : '+ '}`;
  });
};

const GuestsAndRoomsDropdown = ({ children = [], className, withIcon = false }) => {
  const { isRoomLabel } = useGetRoomsLabel();
  const [item, setItem] = useState([]);
  useEffect(() => {
    setItem([Children.toArray(children)?.map((child) => child.props)]);
  }, [children]);

  const placeholder = isRoomLabel ? t('Guests and Rooms') : t('Guests and Units');
  return (
    <Dropdown
      placeholder={placeholder}
      inputProps={{
        noClear: true,
        readOnly: true,
      }}
      renderSelected={(values) => renderSelected(values[0])}
      value={item}
      noClear
      className={className}
      input={withIcon && <IconInput />}
    >
      {Children.toArray(children)}
    </Dropdown>
  );
};

GuestsAndRoomsDropdown.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  withIcon: PropTypes.bool,
};

GuestsAndRoomsDropdown.defaultProps = {
  children: [],
  className: '',
  withIcon: false,
};

export default GuestsAndRoomsDropdown;
