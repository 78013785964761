import { useEffect, useMemo, useState } from 'react';
import find from 'lodash/find';
import uniqBy from 'lodash/uniqBy';
import isEqual from 'lodash/isEqual';
import cn from 'classnames';
import { useLocation, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';

import createStyles from '@guestyci/foundation/createStyles';
import Divider from '@guestyci/foundation/Divider';
import TextField from '@guestyci/foundation/TextField';
import { Row, Col } from '@guestyci/foundation/Layout';
import Spinner from '@guestyci/foundation/Spinner';
import t from '@guestyci/localize/t.macro/t.macro';

import PriceConverter from 'components/PriceConverter';
import DatesAndGuests from 'components/DatesAndGuests';
import { PriceInfoV2 } from 'components/PriceInfo';
import Image from 'components/Image/Image';
import Coupon from 'components/Coupon';
import { UpsellCheckOutItems } from 'components/UpsellCarousel';

import getGroupQuote from 'api/quote/getGroupQuote';
import getQuote from 'api/quote/getQuote';

import {
  calculateFareAccommodationAdjusted,
  calculateHostPayout,
  calculateSubTotalPrice,
  calculateTotalFees,
  combineInvoiceItems,
} from 'utils/money.util';

import useGetListingById from 'hooks/useGetListingById';
import useSearchValues from 'hooks/useSearchValues';
import useDio from 'hooks/useDio';
import useBookNowButtonText from 'hooks/useBookNowButtonText';
import useIsGroupReservation from 'hooks/useIsGroupReservation';

import { useUpsellContext } from 'context/UpsellContext';
import { Fees, Taxes } from 'components/Prices';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';
import { UPSELL } from 'constants/featureToggleNames';
import PaymentSchedule from 'components/PaymentSchedule';
import FirstPaymentDate from 'components/PaymentSchedule/FirstPaymentDate';
import OriginalPrice from 'components/OriginalPrice';
import Promotions from './Promotions';
import RoomsInformation from './RoomsInformation';

const getUniqPromotions = (quote) => {
  const promotions = quote.map((q) => q.promotions);
  return uniqBy(promotions, 'id');
};

const useStyles = createStyles(({ breakpoints: { create }, boxShadow }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 473,
    overflow: 'hidden',
    boxShadow: boxShadow[1],
    borderRadius: 20,
    marginTop: 10,
    background: '#fff',
    [create('xs')]: {
      marginTop: 40,
    },
  },
  contentWrapper: {
    padding: 30,
    [create('xs')]: {
      padding: 15,
    },
    [create('md')]: {
      padding: 30,
    },
  },
  image: {
    '& > img': {
      width: '100%',
      height: 240,
      objectFit: 'cover',
    },
  },
  title: {
    fontSize: 30,
    fontWeight: '600',
    [create('xs')]: {
      fontSize: 24,
    },
  },
  dates: {
    display: 'flex',
    marginBottom: 10,
    [create('xs')]: {},
  },
  dateBlock: {
    '&:first-child': {
      marginRight: 30,
    },
  },
  date: {
    fontSize: 16,
  },
  guests: {
    marginBottom: 10,
  },
  rooms: {
    marginBottom: 10,
  },
  nightsWithPrice: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  oldSubTotal: {
    textDecoration: 'line-through',
  },
  submitButton: {
    fontSize: 18,
    marginTop: 40,
    width: '100%',
  },
  paymentSchedule: {
    marginBottom: -15,
  },
}));

function getSelectedRatePlansGroup(quote, selectedRatePlanId) {
  if (!quote?.results?.length) return [];
  return quote?.results?.map((q) => {
    const { rates: { ratePlans = [] } = {} } = q;
    return find(ratePlans, (rp) => rp.ratePlan._id === selectedRatePlanId);
  });
}

function getSelectedRatePlansSingle(quote, selectedRatePlanId) {
  if (!quote) return [];
  const { rates: { ratePlans = [] } = {} } = quote;
  return [find(ratePlans, (rp) => rp.ratePlan._id === selectedRatePlanId)];
}

const useGetQuote = () => {
  return useMutation(getQuote);
};

const useGetGroupQuote = () => {
  return useMutation(getGroupQuote);
};

const useGetQuoteByFT = ({ quoteId, isCouponApplied }) => {
  const [data, setData] = useState(null);
  const {
    state: {
      ratePlan: { _id: selectedRatePlanId },
    },
  } = useLocation();
  const { isGroupReservation } = useIsGroupReservation();

  const { upsell } = useUpsellContext();

  const { mutateAsync: mutateGroup } = useGetGroupQuote();
  const { mutateAsync: mutateSingle } = useGetQuote();
  const fetchQuote = isGroupReservation ? mutateGroup : mutateSingle;
  const quotes = isGroupReservation ? quoteId : quoteId[0];

  useEffect(() => {
    const getdata = async () => {
      const response = await fetchQuote({ quoteId: quotes });
      setData(response);
    };
    getdata();
  }, [upsell, isCouponApplied]);

  const selectedRatePlans = isGroupReservation
    ? getSelectedRatePlansGroup(data, selectedRatePlanId)
    : getSelectedRatePlansSingle(data, selectedRatePlanId);

  return {
    fetchQuote,
    data,
    selectedRatePlans,
  };
};

const GroupFees = ({ selectedRatePlans }) => {
  const fees = selectedRatePlans?.map((ratePlan) => ratePlan?.ratePlan?.money?.invoiceItems);
  const combinedArray = fees.length && combineInvoiceItems(fees);
  if (!combinedArray?.length) return null;
  return <Fees invoiceItems={combinedArray} />;
};

const GroupTaxes = ({ selectedRatePlans }) => {
  const taxes = selectedRatePlans?.map((ratePlan) => ratePlan?.ratePlan?.money?.invoiceItems);
  const combinedArray = taxes.length && combineInvoiceItems(taxes);
  if (!combinedArray?.length) return null;
  return <Taxes invoiceItems={combinedArray} />;
};

const SubtotalBeforeFees = ({ selectedRatePlans, money = {} }) => {
  const { currency } = money;
  const fareAccommodationAdjusted = useMemo(
    () => calculateFareAccommodationAdjusted(selectedRatePlans),
    [selectedRatePlans]
  );
  const subTotalPrice = useMemo(() => calculateSubTotalPrice(selectedRatePlans), [selectedRatePlans]);
  const hasPrevTotal = !isEqual(money?.fareAccommodation, money?.fareAccommodationAdjusted);
  const showPrevTotal = hasPrevTotal && subTotalPrice > fareAccommodationAdjusted;

  return (
    <Row className="pb-2 pt-2" justify="between" align="end">
      <TextField bold>{t('Subtotal before fees')}</TextField>
      <Col align="end">
        {showPrevTotal && <OriginalPrice money={money} />}
        <TextField bold>
          <PriceConverter amount={fareAccommodationAdjusted} currency={currency} />
        </TextField>
      </Col>
    </Row>
  );
};

const SubtotalBeforeTaxes = ({ selectedRatePlans, money = {} }) => {
  const { currency } = money;
  const fareAccommodationAdjusted = useMemo(
    () => calculateFareAccommodationAdjusted(selectedRatePlans),
    [selectedRatePlans]
  );
  const totalFees = useMemo(() => calculateTotalFees(selectedRatePlans), [selectedRatePlans]);
  const subtotalBeforeTaxes = Math.round(fareAccommodationAdjusted + totalFees);
  if (!totalFees) return null;
  return (
    <Row className="pb-2 pt-2" justify="between" align="end">
      <TextField bold>{t('Subtotal before taxes')}</TextField>
      <TextField bold>
        <PriceConverter amount={subtotalBeforeTaxes} currency={currency} />
      </TextField>
    </Row>
  );
};

const CheckOutSummaryGroupReservation = ({ submit, isLoading, isFormInvalid, onChangePriceAmount }) => {
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const { root, contentWrapper, image, title, submitButton, paymentSchedule } = useStyles();
  const {
    state: {
      quoteData: { quote },
    },
  } = useLocation();

  const quoteIds = quote?.map(({ _id }) => _id);
  const promotions = getUniqPromotions(quote);
  const { fetchQuote: refetch, selectedRatePlans } = useGetQuoteByFT({ quoteId: quoteIds, isCouponApplied });

  const { money, money: { currency } = {} } = selectedRatePlans?.length ? selectedRatePlans[0]?.ratePlan : {};
  const { dioTrack } = useDio();
  const { id } = useParams();
  const { startDate, endDate, minOccupancy, rooms } = useSearchValues();
  const bookNowButtonText = useBookNowButtonText();

  const { upsell } = useUpsellContext();
  const [, isFTUpsellEnabled] = useFeatureToggle(UPSELL);

  const { property, isGettingProperty, isGettingPropertyError, isSuccess } = useGetListingById({ id });

  const hostPayout = useMemo(() => calculateHostPayout(selectedRatePlans), [selectedRatePlans]);

  useEffect(() => {
    if (onChangePriceAmount) {
      onChangePriceAmount(hostPayout);
    }
  }, [hostPayout, onChangePriceAmount]);

  if (isGettingProperty) {
    return (
      <div className={root}>
        <Spinner />
      </div>
    );
  }

  if (isGettingPropertyError) {
    return (
      <div className={root}>
        <h1>{t('Error')}</h1>
      </div>
    );
  }

  const handleTrackBooking = () => {
    const metadata = {
      listings: [
        {
          listing_id: property._id,
          listing_nickname: property.nickname,
          item_title: property.title,
          currency: property.prices.currency,
          price: property.prices.basePrice,
          quantity: 1,
        },
      ],
    };

    if (isFTUpsellEnabled) {
      metadata.upsells = {
        list: upsell?.selectedUpsells,
        total: upsell?.selectedUpsells?.reduce((acc, el) => acc + el.price, 0),
      };
    }

    dioTrack('click_checkout', 'click', metadata);
  };

  return (
    isSuccess && (
      <div className={root}>
        <div className={image}>
          <Image alt="property" src={property?.pictures[0]?.original || property.picture?.thumbnail} h={240} />
        </div>
        <div className={contentWrapper}>
          <TextField className={title}>{property?.title}</TextField>
          <DatesAndGuests startDate={startDate} endDate={endDate} guestsCount={minOccupancy} rooms={rooms} />
          <Divider />
          <RoomsInformation />
          <Promotions promotions={promotions} />
          <Coupon
            quoteId={quoteIds}
            actualDiscount={0}
            getUpdatedQuote={refetch}
            setIsCouponApplied={setIsCouponApplied}
            hostPayout={hostPayout}
            isGroupReservation
          />
          <Divider />
          <UpsellCheckOutItems currency={property?.prices?.currency} />
          <SubtotalBeforeFees selectedRatePlans={selectedRatePlans} money={money} />
          <Divider />
          <GroupFees selectedRatePlans={selectedRatePlans} />
          <SubtotalBeforeTaxes selectedRatePlans={selectedRatePlans} money={money} />
          <Divider />
          <GroupTaxes selectedRatePlans={selectedRatePlans} />
          <Divider />
          <Row className="pb-2 pt-2" justify="between" align="end">
            <TextField bold variant="h3">
              {t('Total')}
            </TextField>
            <Col align="center">
              <TextField data-qa="total-price" bold variant="h3">
                <PriceConverter amount={hostPayout} currency={currency} />
              </TextField>
            </Col>
          </Row>
          <PriceInfoV2 hostPayout={hostPayout} currency={currency} />
          <PaymentSchedule currency={currency} className={paymentSchedule} total={hostPayout} />
          <button
            data-qa="submit"
            type="submit"
            className={cn('btn btn-colored', submitButton)}
            disabled={isLoading || isFormInvalid}
            onClick={(event) => {
              handleTrackBooking();
              submit.current(event);
            }}
          >
            {isLoading ? <Spinner /> : bookNowButtonText}
          </button>
          <FirstPaymentDate total={hostPayout} />
        </div>
      </div>
    )
  );
};

export default CheckOutSummaryGroupReservation;
