import { useLocation, useParams } from 'react-router-dom';
import qs from 'query-string';
import moment from 'moment';

import { DATE_FORMAT } from 'constants/date';
import { ALLOWED_POINT_OF_SALE } from 'constants/constants';
import { useConfigSettings } from 'hooks/useConfigSettings';

class SearchValue {
  #configSettings;

  constructor({ search, id, configSettings }) {
    const params = qs.parse(search);
    this.#configSettings = configSettings;

    this.startDate = params.checkIn && moment(params.checkIn).isValid() ? moment(params.checkIn) : undefined;
    this.endDate = params.checkOut && moment(params.checkOut).isValid() ? moment(params.checkOut) : undefined;
    this.checkInDateLocalized =
      this.startDate && moment(this.startDate).isValid() ? moment(this.startDate).format(DATE_FORMAT) : undefined;
    this.checkOutDateLocalized =
      this.endDate && moment(this.endDate).isValid() ? moment(this.endDate).format(DATE_FORMAT) : undefined;
    this.listingId = id;
    this.minOccupancy = params.minOccupancy;
    this.rooms = params.rooms;
    this.city = params.city;
    this.country = params.country;
    this.currency = params.currency;
    this.minPrice = params.minPrice;
    this.maxPrice = params.maxPrice;
    this.propertyType = params.propertyType;
    this.tags = params.tags;
    this.includeAmenities = params.includeAmenities;
    this.numberOfBedrooms = params.numberOfBedrooms;
    this.numberOfBathrooms = params.numberOfBathrooms;
    this.pointofsale = ALLOWED_POINT_OF_SALE.includes(params.pointofsale) ? params.pointofsale : undefined;
    this.sortBy = params.sortBy;
    this.sortOrder = params.sortOrder;

    if (params.minOccupancy) {
      this.minOccupancy = Number(params.minOccupancy);
    }
    if (params.rooms) {
      this.rooms = Number(params.rooms);
    }
  }

  getParsedAmenities() {
    const amenities = this.includeAmenities?.split(',') || [];
    const amenityTypes = this.#configSettings.allAmenityTypes;
    return amenities.reduce((acc, amenity) => {
      // eslint-disable-next-line no-prototype-builtins
      if (amenityTypes.hasOwnProperty(amenity)) {
        acc.push(amenityTypes[amenity].value);
      }
      return acc;
    }, []);
  }

  getParsedPropertyTypes() {
    const propertyTypes = this.#configSettings.allPropertyTypes;
    const currentPropertyType = propertyTypes[this.propertyType];
    return currentPropertyType ? currentPropertyType.value : null;
  }

  getParsedTags() {
    return this.tags?.split(',') || [];
  }
}

function useSearchValues() {
  const { search } = useLocation();
  const { id } = useParams();
  const configSettings = useConfigSettings();
  return new SearchValue({ search, id, configSettings });
}

export default useSearchValues;
