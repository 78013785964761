const PROMOTION_TYPES = {
  BASIC: 'basic',
  EARLY_BOOKER: 'early_booker',
  LAST_MINUTE: 'last_minute',
  LOS_MIN_NIGHTS: 'los_min_nights',
  LOS_SPECIFIC_LENGTH: 'los_specific_length',
  LOS_DIRECT_BOOKINGS: 'los_direct_bookings',
  MOBILE_RATE: 'mobile_rate',
  MULTI_NIGHT: 'multi_night',
  DAY_OF_WEEK: 'day_of_week',
};

export default PROMOTION_TYPES;
