/* eslint-disable react/jsx-closing-tag-location */

import TextField from '@guestyci/foundation/TextField';
import { Row } from '@guestyci/foundation/Layout';

import PriceConverter from 'components/PriceConverter';
import { DISCOUNT_TYPE } from 'constants/constants';

const DiscountTypeText = ({ coupon: { discountType: type, discount, couponCode, currency }, actualDiscount }) => {
  const discountType = type.toUpperCase();
  const { PERCENT, FIXED } = DISCOUNT_TYPE;

  const discountAmount = {
    [FIXED]: discount,
    [PERCENT]: -actualDiscount?.amount,
  };
  const discountCurrency = {
    [FIXED]: currency,
    [PERCENT]: actualDiscount?.currency,
  };
  const calculatedCurrency = discountCurrency[discountType];
  const calculatedDiscountAmount = discountAmount[discountType];

  return (
    <Row justify="between" className="w-fill pl-0 pr-0">
      <Row>
        <TextField className="ml-2">{couponCode}</TextField>
        {discountType === PERCENT && (
          <TextField data-qa="discount-percentage" className="ml-1">
            -
            {discount}
            %
          </TextField>
        )}
      </Row>
      <TextField>
        -
        {calculatedCurrency && calculatedDiscountAmount && (
          <PriceConverter data-qa="discount-amount" amount={calculatedDiscountAmount} currency={calculatedCurrency} />
        )}
      </TextField>
    </Row>
  );
};

export default DiscountTypeText;
