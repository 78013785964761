import useLocalStorage from 'hooks/useLocalStorage';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';
import { AFE_TYPES } from '@guestyci/shared-enums';
import upsetUpsells from 'api/upsell/upsetUpsells';
import { useMutation } from 'react-query';
import { createContext, useContext } from 'react';
import { GROUP_RESERVATIONS } from 'constants/featureToggleNames';
import { SELECTED_UPSELLS_LOCALSTORE_KEY } from 'constants/constants';

const UpsellContext = createContext();

const UpsellContextProvider = ({ children }) => {
  const { mutateAsync: upsertUpsellRequest, isError } = useMutation(upsetUpsells);

  const [, isGroupReservationsFTEnabled] = useFeatureToggle(GROUP_RESERVATIONS);

  const [upsell, setUpsell] = useLocalStorage(SELECTED_UPSELLS_LOCALSTORE_KEY, {
    quoteId: '',
    inquiryId: '',
    ratePlanId: '',
    selectedUpsells: [],
  });

  const extractDataFromState = ({ state, isGroupReservationsFTEnabled: isFtEnabled }) => {
    if (isFtEnabled) {
      const {
        ratePlan: { _id: ratePlanId },
        inquiryId,
        quoteData,
      } = state;
      return { ratePlanId, quoteId: quoteData.quote[0]?._id, inquiryId };
    }
    const { quoteId, ratePlanId, inquiryId } = state;

    return {
      ratePlanId,
      quoteId,
      inquiryId,
    };
  };

  const leftEqual = (obj, target) => {
    return !Object.keys(obj)
      .map((key) => obj[key] === target[key])
      .includes(false);
  };

  const setCountToUpsell = ({ item, count }) => {
    const upsellItem = upsell.selectedUpsells.find(({ _id }) => _id === item._id);
    if (upsellItem) {
      return upsell.selectedUpsells.map((sell) => (sell._id === item._id ? { ...sell, count: sell.count } : sell));
    }
    return [...upsell.selectedUpsells, { ...item, count }];
  };

  const removeZeroCountUpsell = ({ item }) => {
    return upsell.selectedUpsells.filter(({ _id: id }) => id !== item._id);
  };

  const getCountById = (id) => {
    return upsell.selectedUpsells.find(({ _id }) => _id === id)?.count ?? 0;
  };

  const hasTravelCoverage = upsell.selectedUpsells.some(({ channelFeeType }) =>
    [AFE_TYPES.GUESTY_BASIC_TRAVEL_COVERAGE, AFE_TYPES.GUESTY_EXTENDED_TRAVEL_COVERAGE].includes(channelFeeType));

  const providerValue = {
    upsell,
    hasTravelCoverage,
    selectedUpsells: upsell.selectedUpsells,
    setDataFromState: (state) => {
      const data = extractDataFromState({ state, isGroupReservationsFTEnabled });
      if (!leftEqual(data, upsell)) setUpsell({ ...data, selectedUpsells: [] });
    },
    clearData: () => {
      setUpsell({ quoteId: '', inquiryId: '', ratePlanId: '', selectedUpsells: [] });
    },
    getUpsellItemCountForListingById: (id) => getCountById(id),
    removeUpsellById: async (id) => {
      const { quoteId, inquiryId, ratePlanId } = upsell;
      const selectedUpsells = upsell.selectedUpsells.filter(({ _id }) => _id !== id);

      await upsertUpsellRequest({ additionalFeeIds: selectedUpsells.map(({ _id }) => _id), ratePlanId, quoteId });
      if (isError) return;

      setUpsell({ ratePlanId, quoteId, inquiryId, selectedUpsells });
    },
    setUpsellItemCountForListingById: async (item, count) => {
      const { _id, name, price, channelFeeType } = item;
      const { quoteId, inquiryId, ratePlanId } = upsell;

      const selectedUpsells =
        count > 0
          ? setCountToUpsell({ item: { _id, name, price, channelFeeType }, count })
          : removeZeroCountUpsell({ item: { _id, name, price, channelFeeType } });

      const additionalFeeIds = selectedUpsells.map(({ _id: id }) => id);

      await upsertUpsellRequest({ additionalFeeIds, ratePlanId, quoteId });
      if (isError) return;

      setUpsell({ ratePlanId, quoteId, inquiryId, selectedUpsells });
    },
  };

  return <UpsellContext.Provider value={providerValue}>{children}</UpsellContext.Provider>;
};

const useUpsellContext = () => {
  const context = useContext(UpsellContext);
  if (!context) {
    throw new Error('useUpsellContext must be used within an UpsellContextProvider');
  }
  return context;
};

export { UpsellContext, useUpsellContext };
export default UpsellContextProvider;
